/* eslint-disable */
// 乘法 默认保留两位小数 可传入类型
export function floatMul(arg1, arg2, n = 2, type = '') {
    let m = 0; const s1 = arg1.toString(); const
      s2 = arg2.toString();
    try {
      m += s1.split('.')[1].length;
    } catch (e) {
    }
    try {
      m += s2.split('.')[1].length;
    } catch (e) {

    }
    const num = (Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m));
    if (type === 'ceil') {
      return (Math.ceil(floatMul(num, Math.pow(10, n))) / Math.pow(10, n)).toFixed(n);
    } else if (type === 'floor') {
      return (Math.floor(floatMul(num, Math.pow(10, n))) / Math.pow(10, n)).toFixed(n);
    } else {
      return num.toFixed(n);
    }
  }
  /*
   * 加法 默认不四舍五入 可传入小数位数及类型
   * type: 默认 四舍五入 ceil 向上取整  floor 向下取整
   */
export function floatAdd(arg1, arg2, n = '', type = '') {
    let r1, r2, m, s;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    s = ((arg1 * m) + (arg2 * m)) / m;
    if (!n) {
      return s.toString();
    } else if (type === 'ceil') {
      return (Math.ceil(floatMul(s, Math.pow(10, n))) / Math.pow(10, n)).toFixed(n);
    } else if (type === 'floor') {
      return (Math.floor(floatMul(s, Math.pow(10, n))) / Math.pow(10, n)).toFixed(n);
    } else {
      return s.toFixed(n);
    }
  }

  // 减法 默认不四舍五入 可传入小数位数及类型
export function floatSub(arg1, arg2, l = '', type = '') {
    let r1, r2, m, n, s;
    try {
      r1 = arg1.toString().split('.')[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split('.')[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    if (l) {
      n = l;
    } else {
      n = (r1 >= r2) ? r1 : r2;
    }
    s = ((arg1 * m) - (arg2 * m)) / m;
    if (type === 'ceil') {
      return (Math.ceil(floatMul(s, Math.pow(10, n))) / Math.pow(10, n)).toFixed(n);
    } else if (type === 'floor') {
      return (Math.floor(floatMul(s, Math.pow(10, n))) / Math.pow(10, n)).toFixed(n);
    } else {
      return s.toFixed(n);
    }
  }
export const floatToFixed = (num, type = '', n = 2) => {
    if (type === 'ceil') {
      return (Math.ceil(floatMul(num, Math.pow(10, n))) / Math.pow(10, n)).toFixed(n);
    }
    return (Math.floor(floatMul(num, Math.pow(10, n))) / Math.pow(10, n)).toFixed(n);
  };
