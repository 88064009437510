<template>
  <agc-dialog
    :title="$t('ctp.tips')"
    :visible.sync="rechargeDialogState"
    @close="rechargeDialogState = false"
    width="30%"
  >
    <p class="recharge-tips">
      <i18n path="ctp.error.payError.nomoneytips" tag="a">
        <a
          :href="rechargeUrl"
          class="recharge-link"
          type="primary"
          target="_blank"
        >{{ $t("ctp.tips.recharge") }}</a>
      </i18n>
    </p>
    <p class="recharge-tips">
      {{ $t('ctp.error.payError.nomoneyAttention') }}
    </p>
    <p class="recharge-tips">
      {{ $t('ctp.error.payError.nomoneyListone') }}
    </p>
    <p class="recharge-tips">
      {{ $t('ctp.error.payError.nomoneyListtwo') }}
    </p>
    <span slot="footer">
      <el-button type="primary" @click="rechargeDialogState = false">{{ $t('ctp.close') }}</el-button>
    </span>
  </agc-dialog>
</template>

<script>
export default {
  name: 'Rechargedialog',
  data() {
    return {
      rechargeUrl:
        `${window.location.origin
        }/consumer/cn/console#/myaccount/mainbalance/0/mainbalance-bill/0`,
      rechargeDialogState: false
    };
  },
  methods: {
    handleOpenRecharge() {
      this.rechargeDialogState = true;
    }
  }
};
</script>

<style scoped>
.recharge-tips{
  margin-bottom: 12px;
}
.recharge-link {
  color: #007dff;
  text-decoration: none;
}
.recharge-link:link {
  color: #007dff;
  text-decoration: none;
}
.recharge-link:visited {
  color: #007dff;
  text-decoration: none;
}
.recharge-link:hover {
  color: #3397ff;
  text-decoration: none;
}
.recharge-link:active {
  color: #3397ff;
  text-decoration: none;
}
</style>
