<template>
  <div class="coupons-dialog">
    <agc-dialog
      :title="$t('ctp.selectCoupon')"
      :visible="true"
      width="534px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="availableLabel" name="first">
          <div class="vouchers-container">
            <div v-for="(item, index) in coupons" :key="index">
              <div class="vouchers-item vouchers" @click="selectCoupon(index)">
                <div class="vouchers-img-left">
                  <div>
                    <el-checkbox
                      v-model="item.checked"
                      class="float-left checkbox"
                      @change="selectCoupon(index)"
                    />
                    <div class="float-left">
                      <el-tooltip
                        placement="top-start"
                        :disabled="languageCN"
                        :content="couponText(item)"
                        effect="light"
                      >
                        <div class="goodsName">
                          {{ couponText(item) }}
                        </div>
                      </el-tooltip>
                      <div class="time">
                        {{ $t('ctp.expirationDate') }}{{ ' ' + item.expireDate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vouchers-img-right">
                  <div class="price-info">
                    {{ priceInfo(item) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="unAvailableLabel" name="second">
          <div class="vouchers-container">
            <div v-for="(item, index) in unAvailableCoupons" :key="index">
              <div class="vouchers-item unavailable-vouchers">
                <div class="vouchers-img-left">
                  <div class="unavailable-content">
                    <el-checkbox disabled class="float-left checkbox" />
                    <div class="float-left">
                      <el-tooltip
                        placement="top-start"
                        :disabled="languageCN"
                        :content="couponText(item)"
                        effect="light"
                      >
                        <div class="goodsName grey-text">
                          {{ couponText(item) }}
                        </div>
                      </el-tooltip>
                      <div class="grey-time">
                        {{ $t('ctp.expirationDate') }}{{ ' ' + item.expireDate }}
                      </div>
                      <div class="grey-time">
                        {{ $t('ctp.unAvailableTip') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vouchers-img-right">
                  <div class="price-info">
                    {{ priceInfo(item) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose" class="btn confirm">
          {{ $t('ctp.confirm') }}
        </el-button>
        <el-button @click="handleClose" class="btn cancel">
          {{ $t('ctp.cancel') }}
        </el-button>
      </div>
    </agc-dialog>
  </div>
</template>

<script>
import { CURRENCY_SYMBOL, LANG_CN } from '@/constant';
import { floatToFixed } from '../../util/priceProcessing.js';
export default {
  name: 'CouponsDialog',
  props: {
    couponsList: { type: Array, default: () => [] },
    unAvailableList: { type: Array, default: () => [] }
  },
  data() {
    return {
      activeName: 'first',
      coupons: this.couponsList,
      unAvailableCoupons: this.unAvailableList
    };
  },
  computed: {
    labelContent() {
      return val => val + this.$t('ctp.colon');
    },
    priceInfo() {
      return item => `${CURRENCY_SYMBOL[item.currency]}${floatToFixed(item.limitAmount)}`;
    },
    couponText() {
      return item =>
        this.$t('ctp.fullreduction', {
          limit: item.minimumAmount,
          amount: item.limitAmount
        });
    },
    availableLabel() {
      return this.$t('ctp.availableNum', { num: this.coupons.length });
    },
    unAvailableLabel() {
      return this.$t('ctp.unAvailableNum', {
        num: this.unAvailableCoupons.length
      });
    },
    languageCN() {
      const langKey = AGC.getLanguage().language;
      if (langKey === LANG_CN) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    couponsList(newVal) {
      this.coupons = newVal;
    },
    unAvailableList(newVal) {
      this.unAvailableCoupons = newVal;
    }
  },
  methods: {
    handleClose() {
      this.$emit('hideDialog');
    },
    selectCoupon(index) {
      this.$emit('selectCoupon', index);
    },
    handleClick(tab) {
      this.activeName = tab.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.vouchers-item {
  margin-bottom: 16px;
}
.vouchers {
  display: flex;
  cursor: pointer;
}

/deep/ .el-dialog {
  border-radius: 20px;
  margin-top: 10vh;
}

/deep/ .el-dialog__header {
  padding: 24px 32px 16px;
  border-bottom: none !important;
}

/deep/ .el-dialog__footer {
  padding: 31px 0 32px;
  text-align: center;
}

/deep/ .el-dialog__title {
  line-height: 28px;
  font-size: 20px;
  color: #191919;
}
/deep/ .el-button {
  min-width: 80px;
}

.vouchers-img-left {
  width: 283px;
  height: 102px;
  display: flex;
  background: url("../../../assets/backgroud_left.png") no-repeat;
  background-size: 100% 100%;
  align-items: center;
  text-align: center;
  > div {
    text-align: left;
    .time {
      margin-top: 9px;
      font-size: 14px;
      color: #9e9e9e;
    }
  }
}

.vouchers-img-right {
  width: 138px;
  height: 102px;
  background: url("../../../assets/backgroud_right.png") no-repeat;
  background-size: 100% 100%;
  min-width: 77px;
}

.vouchers-container {
  padding: 32px 55px;
  height: 277px;
  overflow: auto;
}
.goodsName {
  font-size: 18px;
  line-height: 25px;
  color: #191919;
  max-width: 142px;
  max-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.float-left {
  float: left;
}
.checkbox {
  padding: 15px 20px 15px 32px;
}
.price-info {
  font-size: 20px;
  color: #b92e29;
  line-height: 33px;
  text-align: center;
  padding-top: 35px;
}
.coupons-dialog {
  /deep/.el-checkbox__inner {
    border-radius: 20px;
  }
  /deep/ .el-dialog__body {
    padding: 0;
    background: #f2f4f5;
  }
  /deep/ .el-tabs__nav-scroll {
    padding: 0 70px;
    background-color: white;
  }
  /deep/ .el-tabs__header {
    margin: 0;
  }
  .grey-text {
    color: #9e9e9e;
  }
  .unavailable-content {
    .checkbox {
      padding: 26px 20px 15px 32px;
    }
  }
  .unavailable-vouchers {
    display: flex;
    cursor: unset;
    /deep/ .el-checkbox {
      cursor: unset;
    }
    .price-info {
      color: #2a2a2a;
    }
  }

  .grey-time {
    margin-top: 4px;
    font-size: 14px;
    color: #9e9e9e;
  }
}
</style>
