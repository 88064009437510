// 支付指引
export const PAYGUIDE_TAGS = {
  paymentGuidelinesLink: {
    href: '',
    target: '_blank',
    click: () => {
      window.open(
        'https://developer.huawei.com/consumer/cn/doc/start/payment-service-0000001052865979'
      );
    }
  }
};
// 充值指引
export const RECHARGELINK = `${window.location.origin}/consumer/cn/console#/myaccount/mainbalance/0/mainbalance-bill/0`;
// 帮助文档
export const HELPLINK =
  'https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Guides/agc-translation-introduction-0000001056349323';
// 查询代金券参数
export const LANGOBJ = {
  'cn': 'zh',
  'ru': 'ru',
  'en': 'en'
};
// 跳转余额菜单id
export const MENUIDOBJ = {
  'developerlf': '9322440722798181662',
  'developerlfdev': '17464927458519491471',
  'developer': '9322385623856461639'
};
// 优惠券类型  代金券：1 满减券：2
export const VOUCHER_TYPE = 1;
export const COUPON_TYPE = 2;
// 币种符号对照表
export const CURRENCY_SYMBOL = {
  CNY: '￥',
  USD: '$',
  EUR: '€',
  GBP: '￡',
  JPY: 'JPY￥'
};

export const LANG_CN = 'cn';
