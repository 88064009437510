<template>
  <div class="vouchers-dialog">
    <agc-dialog
      :title="$t('ctp.chooseVouchers')"
      :visible="true"
      width="534px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="vouchers-container">
        <div v-for="(item, index) in vouchers" :key="index">
          <div class="vouchers-item vouchers" @click="selectVoucher(index)">
            <div class="vouchers-img-left">
              <div>
                <el-checkbox
                  v-model="item.checked"
                  class="float-left checkbox"
                  @change="selectVoucher(index)"
                />
                <div class="float-left">
                  <div class="goodsName" :title="item.goodsName">
                    {{ item.goodsName }}
                  </div>
                  <div class="time">
                    {{ $t('ctp.expirationDate') }}{{ ' ' + item.expireDate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="vouchers-img-right">
              <div class="price-info">
                {{ priceInfo(item) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose" class="btn confirm">
          {{ $t('ctp.confirm') }}
        </el-button>
        <el-button @click="handleClose" class="btn cancel">
          {{ $t('ctp.cancel') }}
        </el-button>
      </div>
    </agc-dialog>
  </div>
</template>

<script>
import { CURRENCY_SYMBOL } from '@/constant';
import { floatToFixed } from '../../util/priceProcessing.js';
export default {
  name: 'VouchersDialog',
  props: {
    vouchersList: { type: Array, default: () => [] }
  },
  data() {
    return {
      vouchers: this.vouchersList
    };
  },
  computed: {
    labelContent() {
      return val => val + this.$t('ctp.colon');
    },
    priceInfo() {
      return item =>
        `${CURRENCY_SYMBOL[item.currency]}${floatToFixed(
          item.remainingAmount
        )}`;
    }
  },
  watch: {
    vouchersList(newVal) {
      this.vouchers = newVal;
    }
  },
  methods: {
    handleClose() {
      this.$emit('hideDialog');
    },
    selectVoucher(index) {
      this.$emit('selectVoucher', index);
    }
  }
};
</script>

<style lang="scss" scoped>
.vouchers-item {
  margin-bottom: 16px;
}

.vouchers {
  display: flex;
  cursor: pointer;
}
/deep/ .el-dialog {
  border-radius: 20px;
  margin-top: 10vh;
}

/deep/ .el-dialog__body {
  padding: 32px 56px;
  height: 325px;
  overflow: scroll;
  background: #f2f4f5;
}
/deep/ .el-dialog__header {
  padding: 24px 32px 16px;
  border-bottom: none !important;
}

/deep/ .el-dialog__footer {
  padding: 31px 0 32px;
  text-align: center;
}

/deep/ .el-dialog__title {
  line-height: 28px;
  font-size: 20px;
  color: #191919;
}
/deep/ .el-button {
  min-width: 80px;
}

.vouchers-img-left {
  width: 283px;
  height: 102px;
  display: flex;
  background: url("../../../assets/backgroud_left.png") no-repeat;
  background-size: 100% 100%;
  align-items: center;
  text-align: center;
  > div {
    text-align: left;
    .time {
      margin-top: 9px;
      font-size: 14px;
      color: #9e9e9e;
    }
  }
}

.vouchers-img-right {
  width: 138px;
  height: 102px;
  background: url("../../../assets/backgroud_right.png") no-repeat;
  background-size: 100% 100%;
  min-width: 77px;
}

.vouchers-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.goodsName {
  font-size: 18px;
  line-height: 25px;
  color: #191919;
  max-width: 142px;
  max-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.float-left {
  float: left;
}
.checkbox {
  padding: 15px 20px 15px 32px;
}
.price-info {
  font-size: 20px;
  color: #b92e29;
  line-height: 33px;
  text-align: center;
  padding-top: 35px;
}
</style>
